.site-search {
  button > svg {
    width: 17px;
    height: 17px;
  }

  section {
    &.header-search {
      @apply transition-transform duration-300 ease-in-out origin-top;
      top: theme('spacing.header');
      transform: scaleY(0);

      &.toggled {
        transform: scaleY(1);
      }
    }

    form {
      display: flex;
      align-items: center;
      justify-content: space-between;

      label {
        flex-grow: 1;

        input {
          @apply appearance-none text-black border border-dark w-full;
          border-top-left-radius: var(--spinoko-border-radius);
          border-bottom-left-radius: var(--spinoko-border-radius);
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }

      input[type='submit'] {
        @apply bg-light text-dark font-bold border border-dark cursor-pointer;
        border-top-right-radius: var(--spinoko-border-radius);
        border-bottom-right-radius: var(--spinoko-border-radius);
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
