body {
  .entry-content {
    & > h1 {
      @apply text-4xl font-bold sp-mb-4;
    }

    & > h2,
    .wp-block-columns h2,
    .wp-block-group h2,
    .show-more-less-block h2 {
      @apply text-3xl font-bold sp-mb-4;
    }

    & > h3,
    .wp-block-columns h3,
    .wp-block-group h3,
    .show-more-less-block h3 {
      @apply text-2xl font-bold sp-mb-4;
    }

    & > h4,
    .wp-block-columns h4,
    .wp-block-group h4,
    .show-more-less-block h4 {
      @apply text-xl font-bold sp-mb-4;
    }

    & > h5,
    .wp-block-columns h5,
    .wp-block-group h5,
    .show-more-less-block h5 {
      @apply text-lg font-bold sp-mb-4;
    }
  }

  .entry-content,
  .comment-content {
    p:not([class*='mb-']) {
      @apply sp-mb-4;
    }

    ul:not(.list-none) {
      @apply list-disc sp-mb-4 sp-pl-6;
      ul {
        @apply sp-mb-0;
      }
    }

    ol {
      @apply list-decimal sp-mb-4 sp-pl-6;
      ol {
        @apply sp-mb-0;
      }
    }
  }
}

.entry-content,
.entry-content::before,
.entry-content::after,
.entry-footer,
.entry-footer::before,
.entry-footer::after {
  display: block;
  clear: both;
}

[class^='wp-block'] {
  @apply sp-mb-4;
}

figure[class^='wp-block-embed'] {
  @apply spinoko_shadow spinoko_rounded sp-p-1;
}

[class^='wp-block-table'] table tr th,
[class^='wp-block-table'] table tr td,
table[class^='wp-block-table'] th,
table[class^='wp-block-table'] td {
  @apply border sp-p-1;
}

.wp-block-code,
.wp-block-preformatted,
code {
  @apply bg-light border overflow-x-scroll sp-p-2;
}

.wp-block-verse {
  @apply border overflow-x-scroll sp-p-2;
}

.wp-block-pullquote {
  @apply border-b border-neutral sp-py-5;

  &:not(.is-style-solid-color) {
    &:before {
      content: '"';
      font-size: 3rem;
      line-height: 1.1;
      @apply text-white bg-link rounded-full size-12 block sp-mx-auto sp-p-2;
    }
  }
}

.post-password-form {
  @apply spinoko_shadow spinoko_rounded sp-p-4;

  input[type='password'] {
    @apply border rounded sp-py-2 sp-px-4;
  }

  input[type='submit'] {
    @apply border rounded sp-py-2 sp-px-4;
  }
}

.entry-content {
  input,
  textarea {
    @apply border border-neutral rounded-none max-w-full;
    @apply spinoko_rounded sp-mb-4 sp-p-2;
    width: 20rem;
  }

  textarea {
    @apply h-16;
  }

  form {
    button,
    input[type='submit'] {
      @apply bg-dark text-light font-bold cursor-pointer;
      @apply spinoko_rounded sp-py-1 sp-px-4;
    }
  }
}
