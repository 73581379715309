/** Spinoko Styles in Footer **/

@import 'modules/base';
@import 'modules/fixes';
@import 'modules/search';
@import 'modules/alignment';
@import 'modules/media';
@import 'modules/comments';
@import 'modules/posts-navigation';
@import 'modules/footer-navigation';

@import 'modules/blocks/games-grid';
@import 'modules/blocks/show-more-less';

@import 'modules/tippy';
